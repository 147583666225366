html {
  font-family: "Poppins", sans-serif;
}

body {
  color: #fff;
}

a {
  cursor: pointer;
}

.App {
  overflow: hidden;
}

p,
span {
  word-break: break-word;
}
